import { useAuth0 } from '@auth0/auth0-react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { Button, Empty, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useQuestionSummary } from '../../../api/CodeFrames';
import { useCodeFrames } from '../../../api/Questions';
import Loading from '../../../components/Loading';
import {
  ChartValueType,
  MatomoEvent,
  ThemeChartSegmentType,
  UserRoleType,
} from '../../../constants';
import useMatomo from '../../../hooks/useMatomo';
import useParams from '../../../hooks/useParams';
import { queriesType, queryType } from '../../../types';
import ManageThemesModal from '../ManageThemesModal';
import OpenEndedQuestionsSelect from '../OpenEndedQuestionsSelect';
import ChartValueTypeToggle from './ChartValueTypeToggle';
import QuestionSummary from './QuestionSummary';
import RawDataExportModal from './RawDataExportModal';
import SegmentThemesCard from './SegmentThemesCard';
import ThemesChart from './ThemesChart';

function ThemesTab({
  questions,
  questionResponseOptions,
  selectedQuestionId,
  setSelectedQuestionId,
}) {
  const { surveyId } = useParams();
  const { trackPageView, trackEvent } = useMatomo();
  const { user } = useAuth0();
  const roles = user['https://avalancheinsights.com/roles'];

  const [segments, setSegments] = useState([]);
  const [isThemesModalVisible, setIsThemesModalVisible] = useState(false);
  const [chartValueType, setChartValueType] = useState(ChartValueType.PERCENTAGE);
  const [hiddenThemes, setHiddenThemes] = useState([]);
  const [isExportModalVisible, setExportModalVisible] = useState(false);

  const openEndedQuestions = questions.data.filter((q) => q.has_themes);
  const CACHED_BREAKDOWN_KEY = `selectedBreakdown${selectedQuestionId}`;
  const SEGMENTS_CACHE_KEY = `segments${selectedQuestionId}`;
  const question = questions.data.find((q) => q.id === selectedQuestionId);

  const { data: summary, isFetching: summaryFetching } = useQuery(
    ['questionSummary', { surveyId, codeFrameId: question.code_frame_id }],
    useQuestionSummary(),
    { enabled: !!selectedQuestionId && !!question.code_frame_id },
  );

  const getCodeFrames = useCodeFrames();
  const segmentThemes = useQueries({
    queries: segments.map((segment) => {
      const params = new URLSearchParams({ only_final: true });
      if (segment.segments) {
        Object.keys(segment.segments).forEach((key) => {
          if (segment.segments[key]?.length) {
            params.append(key, segment.segments[key].join('_$SEPARATOR$_'));
          }
        });
      }
      return {
        queryKey: [
          'codeFrames',
          { questionIds: selectedQuestionId, filters: params.toString(), surveyId, hiddenThemes },
        ],
        queryFn: getCodeFrames,
        enabled: !!selectedQuestionId,
        select: (data) => {
          if (!data.data.length) {
            return { themes: [], total_response_count: 0, segmentId: segment.id };
          }
          return { ...data.data[0].theme_json, segmentId: segment.id };
        },
        cacheTime: 0,
      };
    }),
  });

  const cacheSegments = (updatedSegments) => {
    localStorage.setItem(SEGMENTS_CACHE_KEY, JSON.stringify(updatedSegments));
  };

  useEffect(() => {
    setHiddenThemes([]);
    setChartValueType(ChartValueType.PERCENTAGE);
    const cachedSegments = JSON.parse(localStorage.getItem(SEGMENTS_CACHE_KEY));
    setSegments(
      cachedSegments || [
        {
          id: uuidv4(),
          active: true,
          segments: null,
        },
      ],
    );
  }, [SEGMENTS_CACHE_KEY]);

  useEffect(() => {
    if (
      segmentThemes.length &&
      !segmentThemes.some((r) => r.isLoading) &&
      segments.some((s) => !s.text)
    ) {
      // add text to new segments (segments without text are new)
      setSegments((prev) => {
        const updatedSegments = prev.map((segment) => {
          if (!segment.text) {
            const segmentText = segment.segments
              ? Object.keys(segment.segments)
                  .flatMap((key) => segment.segments[key])
                  .join(', ')
              : 'All Respondents';
            const responseCount = segmentThemes.find((st) => st.data.segmentId === segment.id).data
              .total_response_count;
            return { ...segment, text: `${segmentText} (${responseCount})` };
          }
          return segment;
        });
        localStorage.setItem(SEGMENTS_CACHE_KEY, JSON.stringify(updatedSegments));
        return updatedSegments;
      });
    }
  }, [segmentThemes, segments, SEGMENTS_CACHE_KEY]);

  useEffect(() => {
    trackPageView('Theme Explorer');
  }, [trackPageView]);

  const saveSegment = async (segmentType, selectedSegments) => {
    const newSegments = await Promise.all(
      selectedSegments.map((segment) => ({
        id: uuidv4(),
        active: true,
        segments: segment,
        segmentType,
      })),
    );
    let updatedSegments;
    if (segmentType === ThemeChartSegmentType.BREAKDOWN) {
      const allRespondentSegment = segments.find((item) => !item.segmentType);
      allRespondentSegment.active = false;
      const customSegments = segments.filter((s) => s.segmentType === ThemeChartSegmentType.CUSTOM);
      updatedSegments = [allRespondentSegment].concat(newSegments).concat(customSegments);
    } else if (segmentType === ThemeChartSegmentType.CUSTOM) {
      updatedSegments = segments.concat(newSegments);
    }
    setSegments(updatedSegments);
    cacheSegments(updatedSegments);
  };

  const toggleSegment = (id) => {
    const updatedSegments = segments.map((s) => (s.id === id ? { ...s, active: !s.active } : s));
    setSegments(updatedSegments);
    cacheSegments(updatedSegments);
  };

  const editSegmentName = (id, value) => {
    const updatedSegments = segments.map((s) => (s.id === id ? { ...s, text: value } : s));
    setSegments(updatedSegments);
    cacheSegments(updatedSegments);
  };

  const removeSegment = (id) => {
    const updatedSegments = segments.filter((s) => s.id !== id);
    setSegments(updatedSegments);
    cacheSegments(updatedSegments);
  };

  const clearAllSegments = () => {
    const allRespondentSegment = segments.find((item) => !item.segmentType);
    allRespondentSegment.active = true;
    setSegments([allRespondentSegment]);
    localStorage.removeItem(SEGMENTS_CACHE_KEY);
  };

  if (segmentThemes.some((r) => r.isLoading) || summaryFetching) {
    return <Loading />;
  }

  return (
    <Space direction="vertical" size="large">
      <OpenEndedQuestionsSelect
        openEndedQuestions={openEndedQuestions}
        selectedQuestionId={selectedQuestionId}
        setSelectedQuestionId={setSelectedQuestionId}
        extra={
          !roles.includes(UserRoleType.CLIENT) && [
            <Button key="export" onClick={() => setExportModalVisible(true)} size="small">
              Export raw data
            </Button>,
          ]
        }
      />
      {segmentThemes[0].data.themes.length > 0 ? (
        <>
          {summary?.data &&
            (roles.includes(UserRoleType.ADMINISTRATOR) ||
              roles.includes(UserRoleType.EDITOR) ||
              summary.data.summary_generated) && (
              <QuestionSummary question={question} summary={summary.data} />
            )}
          <SegmentThemesCard
            segments={segments.filter((s) => s.text)}
            clearAllSegments={clearAllSegments}
            removeSegment={removeSegment}
            toggleSegment={toggleSegment}
            questions={questions.data.filter((q) => q.is_filterable)}
            questionResponseOptions={questionResponseOptions}
            saveSegment={saveSegment}
            editSegmentName={editSegmentName}
            cachedBreakdownKey={CACHED_BREAKDOWN_KEY}
          />
          {!segmentThemes.some((r) => r.isLoading) ? (
            <ThemesChart
              question={question}
              activeSegments={segments.filter((s) => s.active)}
              segmentThemes={segmentThemes}
              chartValueType={chartValueType}
              hiddenThemes={hiddenThemes}
            />
          ) : (
            <Loading />
          )}
          <Space>
            <ChartValueTypeToggle
              key="chartValueType"
              chartValueType={chartValueType}
              setChartValueType={setChartValueType}
            />
            <Button
              key="manageThemes"
              size="small"
              onClick={() => {
                trackEvent(MatomoEvent.MANAGE_THEMES_CLICKS);
                setIsThemesModalVisible(true);
              }}
            >
              Manage themes
            </Button>
          </Space>
          <ManageThemesModal
            themes={segmentThemes[0].data.themes}
            saveThemes={setHiddenThemes}
            hiddenThemes={hiddenThemes}
            isModalVisible={isThemesModalVisible}
            setIsModalVisible={setIsThemesModalVisible}
            codeFrameId={question.code_frame_id}
            surveyId={surveyId}
          />
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='Themes are not yet finalized. To finalize them, click "Update & View Theme Explorer" located at the top-right of the page in Theme Engine.'
        />
      )}
      <RawDataExportModal
        visible={isExportModalVisible}
        closeModal={() => setExportModalVisible(false)}
        openEndedQuestions={openEndedQuestions.filter((q) => q.code_frame_id)}
        selectedQuestionId={selectedQuestionId}
        surveyId={surveyId}
      />
    </Space>
  );
}

ThemesTab.defaultProps = {
  selectedQuestionId: undefined,
};

ThemesTab.propTypes = {
  questions: queryType.isRequired,
  questionResponseOptions: queriesType.isRequired,
  selectedQuestionId: PropTypes.number,
  setSelectedQuestionId: PropTypes.func.isRequired,
};

export default ThemesTab;
