import { SURVEY_ID_HEADER } from '../constants';
import useFetch from './useFetch';

export function useSurveyList() {
  const fetcher = useFetch();
  return () => fetcher({ path: 'v1/surveys/' });
}

export function useSurvey() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { surveyId }] = queryKey;
    return fetcher({ path: `v1/surveys/${surveyId}/`, headers: { [SURVEY_ID_HEADER]: surveyId } });
  };
}

export function useUpdateSurvey() {
  const fetcher = useFetch();
  return ({ surveyId, data }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/update/`,
      method: 'PATCH',
      data,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
}

export function useQuestions() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { surveyId, filters }] = queryKey;
    return fetcher({
      path: `v1/surveys/${surveyId}/questions/`,
      filters,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
  };
}

export function useCreateSurvey() {
  const fetcher = useFetch();
  return ({ data }) => fetcher({ path: 'v1/surveys/create/', method: 'POST', data });
}

export function usePublishSurvey() {
  const fetcher = useFetch();
  return ({ surveyId, data }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/publish/`,
      method: 'PUT',
      data: JSON.stringify(data),
    });
}

export function useArchiveSurvey() {
  const fetcher = useFetch();
  return ({ surveyId, data }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/archive/`,
      method: 'PUT',
      data: JSON.stringify(data),
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
}

export function useSurveyClients() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { surveyId }] = queryKey;
    return fetcher({
      path: `v1/surveys/${surveyId}/clients/`,
    });
  };
}

export function useSurveyInvitations() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { surveyId }] = queryKey;
    return fetcher({
      path: `v1/surveys/${surveyId}/invitations/`,
    });
  };
}

export function useDeleteGuest() {
  const fetcher = useFetch();
  return ({ userId, surveyId }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/clients/${userId}/delete/`,
      method: 'DELETE',
    });
}

export function useUpdateGuest() {
  const fetcher = useFetch();
  return ({ userId, surveyId, data }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/clients/${userId}/update/`,
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}

export function useSurveySummary() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { surveyId }] = queryKey;
    return fetcher({
      path: `v1/surveys/${surveyId}/summary/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
  };
}

export function useUpdateSurveySummary() {
  const fetcher = useFetch();
  return ({ surveyId, data }) =>
    fetcher({
      path: `v1/surveys/${surveyId}/update-summary/`,
      method: 'PATCH',
      data: JSON.stringify(data),
    });
}
