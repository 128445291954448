import { MenuOutlined } from '@ant-design/icons';
import { Button, Card, Col, Dropdown, Row, Space, Spin, Typography } from 'antd';
import html2canvas from 'html2canvas';
import { jsPDF as PDF } from 'jspdf';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactFC from 'react-fusioncharts';
import styled from 'styled-components';
import { ChartValueType } from '../../../constants';
import { getThemesBarChart } from '../../../fusion-charts/ChartHelper';
import useParams from '../../../hooks/useParams';
import { questionType } from '../../../types';

const { Text, Title } = Typography;

const LegendColor = styled.div`
  width: 11px;
  height: 11px;
  margin-left: 14px;
  background-color: ${(props) => props.$color};
`;

const StyledSegmentDiv = styled.div`
  margin-left: 14px;
`;

const StyledLegendText = styled.span`
  font-size: 15px;
  color: #7c7c7c;
`;

const StyledButton = styled(Button)`
  color: #9a9a9a;
  border-color: #c0c0c0;
  width: 18px;
  height: 18px;
  margin: 1px 12px;

  .anticon {
    font-size: 11px;
  }
`;

const PDF_HEIGHT = 792;
const PDF_WIDTH = 612;
const PDF_MARGIN = 30;

function ThemesChart({ question, activeSegments, segmentThemes, chartValueType, hiddenThemes }) {
  const chartRef = useRef();
  const { surveyId } = useParams();
  const [segmentsWithCharts, setSegmentsWithCharts] = useState([]);
  const [isChartDownloading, setChartDownloading] = useState(false);

  const legendItems = [
    { color: '#18C2C3', label: 'Theme' },
    { color: '#86E9DE', label: 'Subtheme' },
  ];

  const getSegmentParams = (segmentId, segments) => {
    if (!segments) {
      return null;
    }
    // we need to construct a separate set of search params where `+` and `&` are replaced
    // if we don't, the browser misinterprets those special characters
    // and we can't recover the filters
    const encodedParams = new URLSearchParams({ segmentId });
    Object.keys(segments).forEach((key) => {
      if (segments[key]?.length) {
        encodedParams.append(
          key,
          segments[key]
            .join('_$SEPARATOR$_')
            .replaceAll('+', '_$PLUS_')
            .replaceAll('&', '_$AMPERSAND_'),
        );
      }
    });
    return encodedParams;
  };

  useEffect(() => {
    const maxSegmentValues = [];
    if (activeSegments.length) {
      const charts = activeSegments.map((s) => {
        const { data } = segmentThemes.find((t) => t.data.segmentId === s.id);
        // Filter out themes that are not selected for display
        const visibleThemes = data.themes
          .filter((t) => !hiddenThemes.includes(t.data.id))
          .map((t) => {
            const children = t.children.filter((c) => !hiddenThemes.includes(c.data.id));
            return { ...t, children };
          });

        const segmentMax = visibleThemes.reduce((acc, t) => {
          const value =
            chartValueType === ChartValueType.PERCENTAGE
              ? t.data.percentage
              : t.data.response_count;
          return acc > value ? acc : value;
        }, 0);
        maxSegmentValues.push(segmentMax);

        const chart = getThemesBarChart({
          themes: visibleThemes,
          questionId: question.id,
          surveyId,
          filters: getSegmentParams(s.id, s.segments),
          chartValueType,
        });
        return { ...s, chart };
      });

      // calculate and add max value to all charts to ensure consistent y-axis
      const maxChartValue = maxSegmentValues.reduce((a, c) => (a > c ? a : c), 0);
      let roundedMaxChartValue = maxChartValue;
      // if maxChartValue is a multiple of 10, round up to the next 10 to avoid display issues
      if (maxChartValue % 10 === 0) {
        // if value type is percentage do not exceed 100%
        if (chartValueType !== ChartValueType.PERCENTAGE || maxChartValue !== 100) {
          roundedMaxChartValue += 10;
        }
      } else {
        roundedMaxChartValue = Math.ceil(maxChartValue / 10) * 10; // Round up to the nearest 10s
      }

      Object.keys(charts).forEach((key) => {
        charts[key].chart.dataSource.chart.yAxisMaxValue = roundedMaxChartValue;
        charts[key].chart.dataSource.chart.yAxisMinValue = roundedMaxChartValue;
      });
      setSegmentsWithCharts(charts);
    }
  }, [segmentThemes, activeSegments, chartValueType, question, surveyId, hiddenThemes]);

  const segmentChartConfig = (chartConfig) => {
    const config = { ...chartConfig };
    config.dataSource = { ...chartConfig.dataSource };
    config.dataSource.chart = {
      ...chartConfig.dataSource.chart,
      showLabels: 0,
    };
    return config;
  };

  const enableChartExport = (chartConfig) => {
    const config = { ...chartConfig };
    config.dataSource = { ...chartConfig.dataSource };
    config.dataSource.chart = {
      ...chartConfig.dataSource.chart,
      exportEnabled: true,
      caption: question.text,
      exportFileName: question.text,
      showZeroPlaneValue: 0,
      showLimits: 0,
      showAxisLines: 0,
    };
    return config;
  };

  const rows = [...Array(Math.ceil(segmentsWithCharts.length / 4))];
  const segmentRows = rows.map((_, idx) => segmentsWithCharts.slice(idx * 4, idx * 4 + 4));

  // image from react component: https://www.robinwieruch.de/react-component-to-image/
  // canvas to PDF: https://hackmd.io/@n6kGXbvAST2zb6hPLZ6sNQ/HJTVYZz8n#Convert-HTML-to-PDF
  const handleDownloadImage = async (extension) => {
    setChartDownloading(true);
    const element = chartRef.current;
    const canvas = await html2canvas(element);
    const chartImg = canvas.toDataURL('image/png', 1.0);
    const filename = question.text.replace(/[^A-Z0-9]+/gi, '_');

    if (extension === 'png') {
      const link = document.createElement('a');
      link.href = chartImg;
      link.download = `${filename}.png`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      const pdf = new PDF('p', 'pt', 'letter');

      const innerHeight = PDF_HEIGHT - 2 * PDF_MARGIN;
      const innerWidth = PDF_WIDTH - 2 * PDF_MARGIN;
      const imgHeight = (innerWidth / canvas.width) * canvas.height;

      // if the image is too tall to fit on one page split it into multiple pages
      if (imgHeight > innerHeight) {
        let heightUnprinted = imgHeight;
        let position = PDF_MARGIN;
        while (heightUnprinted > 0) {
          pdf.addImage(chartImg, 'PNG', PDF_MARGIN, position, innerWidth, imgHeight);

          // draw the top and bottom margin if needed
          pdf.setFillColor(255, 255, 255);
          pdf.rect(0, 0, PDF_WIDTH, PDF_MARGIN, 'F');
          pdf.rect(0, PDF_HEIGHT - PDF_MARGIN, PDF_WIDTH, PDF_MARGIN, 'F');

          heightUnprinted -= innerHeight;
          position -= innerHeight; // next vertical placement

          // add another page if there's more contents to print
          if (heightUnprinted > 0) {
            pdf.addPage();
          }
        }
      } else {
        pdf.addImage(chartImg, 'PNG', PDF_MARGIN, PDF_MARGIN, innerWidth, imgHeight);
      }

      pdf.save(`${filename}.pdf`);
    }
    setChartDownloading(false);
  };

  const menuItems = [
    {
      label: 'Export As PNG',
      key: 'png',
      onClick: () => handleDownloadImage('png'),
      style: {
        fontSize: '12px',
        padding: '0 8px',
        fontFamily: 'Roboto',
      },
    },
    {
      label: 'Export As PDF',
      key: 'pdf',
      onClick: () => handleDownloadImage('pdf'),
      style: {
        fontSize: '12px',
        padding: '0 8px',
        fontFamily: 'Roboto',
      },
    },
  ];

  return (
    <Card size="small">
      <div ref={chartRef}>
        <Space direction="vertical" size="middle">
          <Row justify="center">
            <Title level={5}>{question.text}</Title>
          </Row>
          {segmentRows?.map((row, rowIdx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={rowIdx}>
              <Row>
                {rowIdx === 0 &&
                  row?.length === 1 &&
                  row.map((segment) => (
                    <Col key={segment.id} span={24}>
                      <Text style={{ marginLeft: '27%', display: 'block' }}>{segment.text}</Text>
                    </Col>
                  ))}
                {rowIdx === 0 &&
                  row?.length === 2 &&
                  row.map((segment, index) => {
                    if (index === 0) {
                      return (
                        <Col key={segment.id} span={14}>
                          <Text style={{ marginLeft: '30%', display: 'block' }}>
                            {segment.text}
                          </Text>
                        </Col>
                      );
                    }
                    return (
                      <Col key={segment.id} span={10}>
                        <StyledSegmentDiv>{segment.text}</StyledSegmentDiv>
                      </Col>
                    );
                  })}
                {rowIdx === 0 &&
                  row?.length === 3 &&
                  row.map((segment, index) => {
                    if (index === 0) {
                      return (
                        <Col key={segment.id} span={10}>
                          <Text style={{ marginLeft: '34%', display: 'block' }}>
                            {segment.text}
                          </Text>
                        </Col>
                      );
                    }
                    return (
                      <Col key={segment.id} span={7}>
                        <StyledSegmentDiv>{segment.text}</StyledSegmentDiv>
                      </Col>
                    );
                  })}
                {(rowIdx > 0 || row?.length >= 4) &&
                  row.map((segment, index) => {
                    if (index % 4 === 0) {
                      return (
                        <Col key={segment.id} span={7} offset={1}>
                          <Text style={{ marginLeft: '35%', display: 'block' }}>
                            {segment.text}
                          </Text>
                        </Col>
                      );
                    }
                    return (
                      <Col key={segment.id} span={5}>
                        <StyledSegmentDiv>{segment.text}</StyledSegmentDiv>
                      </Col>
                    );
                  })}
              </Row>
              {rowIdx === 0 && row?.length !== 1 && (
                <Row justify="end">
                  <Spin size="small" spinning={isChartDownloading}>
                    <Dropdown
                      menu={{
                        items: menuItems,
                        selectable: false,
                        style: {
                          border: '1px solid',
                          borderRadius: '0',
                          boxShadow: '#999999 2px 2px 5px',
                          padding: '3px',
                        },
                      }}
                      placement="bottomRight"
                    >
                      <StyledButton
                        icon={<MenuOutlined />}
                        onClick={handleDownloadImage}
                        data-html2canvas-ignore
                      />
                    </Dropdown>
                  </Spin>
                </Row>
              )}
              <Row className="hide-chart-caption">
                {rowIdx === 0 &&
                  row?.length === 1 &&
                  row.map((segment) => (
                    <Col key={segment.id} span={24} data-cy="theme-chart">
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <ReactFC {...enableChartExport(segment.chart)} />
                    </Col>
                  ))}
                {rowIdx === 0 &&
                  row?.length === 2 &&
                  row.map((segment, index) => {
                    if (index === 0) {
                      return (
                        <Col key={segment.id} span={14}>
                          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                          <ReactFC {...segment.chart} />
                        </Col>
                      );
                    }
                    return (
                      <Col key={segment.id} span={10}>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <ReactFC {...segmentChartConfig(segment.chart)} />
                      </Col>
                    );
                  })}
                {rowIdx === 0 &&
                  row?.length === 3 &&
                  row.map((segment, index) => {
                    if (index === 0) {
                      return (
                        <Col key={segment.id} span={10}>
                          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                          <ReactFC {...segment.chart} />
                        </Col>
                      );
                    }
                    return (
                      <Col key={segment.id} span={7}>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <ReactFC {...segmentChartConfig(segment.chart)} />
                      </Col>
                    );
                  })}
                {(rowIdx > 0 || row?.length >= 4) &&
                  row.map((segment, index) => {
                    if (index % 4 === 0) {
                      return (
                        <Col key={segment.id} span={7} offset={1}>
                          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                          <ReactFC {...segment.chart} />
                        </Col>
                      );
                    }
                    return (
                      <Col key={segment.id} span={5}>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <ReactFC {...segmentChartConfig(segment.chart)} />
                      </Col>
                    );
                  })}
              </Row>
            </div>
          ))}
          <Row justify="center">
            <Space size={7}>
              {legendItems.map((item) => (
                <React.Fragment key={item.label}>
                  <LegendColor $color={item.color} />
                  <StyledLegendText>{item.label}</StyledLegendText>
                </React.Fragment>
              ))}
            </Space>
          </Row>
        </Space>
      </div>
    </Card>
  );
}

ThemesChart.propTypes = {
  question: questionType.isRequired,
  activeSegments: PropTypes.arrayOf(PropTypes.object).isRequired,
  segmentThemes: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartValueType: PropTypes.string.isRequired,
  hiddenThemes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ThemesChart;
