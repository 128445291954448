export const INTERCOM_APP_ID = 'mlhfh9u4';
export const INTERCOM_API_BASE = 'https://api-iam.intercom.io';

export const SurveyStatus = {
  PUBLISHED: 'Published',
  PUBLISH_IN_PROGRESS: 'Publish in Progress',
  UNPUBLISHED: 'Unpublished',
};

export const MATOMO_URL = 'https://avalancheinsights.matomo.cloud/';
export const EVENT_METRICS = 'Event Metrics';
export const MatomoEvent = {
  UPLOAD_SURVEY_BUTTON_CLICKS: 'upload-survey-button-click',
  OPEN_SURVEY_UPLOAD_FORM: 'open-survey-upload-form',
  EXPLORE_THEME_CLICKS: 'explore-theme-click',
  EXPLORE_SENTIMENT_CLICKS: 'explore-sentiment-click',
  MANAGE_THEMES_CLICKS: 'manage-themes-click',
  ADD_BUTTON_IN_SEGMENTS_CLICKS: 'add-button-segment-click',
  SEARCH_BAR_RESPONSE_CLICKS: 'search-bar-response-click',
  UPDATE_USER_ROLE: 'update-user-role',
  GENERATE_THEME_SUMMARY: 'generate-theme-summary',
  EDIT_THEME_SUMMARY: 'edit-theme-summary',
  GENERATE_QUESTION_SUMMARY: 'generate-question-summary',
  EDIT_QUESTION_SUMMARY: 'edit-question-summary',
  GENERATE_SURVEY_SUMMARY: 'generate-survey-summary',
  EDIT_SURVEY_SUMMARY: 'edit-survey-summary',
};

export const THEME_ENGINE_RANDOM_SAMPLE = 30;

export const QCPhase = {
  PROCESSING: 'Preprocessing Data',
  READY: 'Ready for Analysis',
  REMAPPING: 'Remapping in Progress',
  REMAPPING_COMPLETE: 'Remapping Complete',
  COMPLETE: 'Analysis Completed',
};

export const PhaseType = {
  DISCOVERY: 'discovery',
  ORGANIZATION: 'organization',
};

export const SortItems = {
  MOST_PROMINENT: 'By most prominent',
  LEAST_PROMINENT: 'By least prominent',
  A_TO_Z: 'From A to Z',
  Z_TO_A: 'From Z to A',
};

export const UserRoleType = {
  ADMINISTRATOR: 'Administrator',
  EDITOR: 'Editor',
  VIEWER: 'Viewer',
  CLIENT: 'Client',
};

export const ThemeChartSegmentType = {
  BREAKDOWN: 'breakdown',
  CUSTOM: 'custom',
};

export const ThemeEngineQueryKey = {
  THEMES: 'themes',
  RESPONSES: 'responses',
  SUGGESTED_THEMES: 'suggestedThemes',
  THEME_ORGANIZATION_THEMES: 'themeOrganizationThemes',
};

export const SurveyDashboardTabs = {
  SUMMARY: 'Summary',
  THEMES: 'Themes',
  SENTIMENTS: 'Sentiments',
  RESPONSES: 'Responses',
};

export const ThemeDiscoveryTabs = {
  RANDOM: 'random',
  SUGGESTED_THEMES: 'suggestedThemes',
  ALL: 'all',
  THEME: 'theme',
};

export const SurveyListTabs = {
  SURVEYS: 'Surveys',
  ARCHIVE: 'Archive',
};

export const ThemeOrganizationContainer = {
  NEW: 'new',
  OMITTED: 'omitted',
  UNGROUPED: 'ungrouped',
};

export const QuestionTypeValues = {
  REMOVE: 0,
  HAS_THEMES: 1,
  IS_SINGLE_SELECT: 2,
  IS_KEY: 3,
  IS_METADATA: 4,
  IS_MULTI_SELECT: 5,
};

export const SurveyTypes = [
  'Pulse',
  'Engagement',
  'Exit',
  'Culture',
  'Wellness',
  'Onboarding',
  'Customer Feedback',
  'Other',
];

export const SurveyTools = [
  'Survey Monkey',
  'Qualtrics',
  'Google Forms',
  'Microsoft Forms',
  'Internal Data',
  'Other',
];

export const SettingsPageTabs = {
  USERS: 'Users',
  INVITATIONS: 'Invitations',
};

export const SURVEY_ID_HEADER = 'Requires-Survey-ID';

export const ChartValueType = {
  PERCENTAGE: 'percentage',
  COUNT: 'count',
};

export const SummaryLoadingPhrases = [
  'Engineering a prompt...',
  'Selecting theme...',
  'Selecting responses...',
  'Processing responses...',
  'Heating up GPUs...',
  'Charging flux capacitors...',
  'Initiating quantum leap...',
  'Moving the needle...',
  'Sprinkling AI magic...',
  'Preparing summary...',
  'Running spell checker...',
];

export const SUMMARY_COLLAPSE_KEY = 'summary';

export const ExportOption = {
  ALL: 'all',
  THEMES: 'themes',
  CUSTOM: 'custom',
};
